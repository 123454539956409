import React from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql } from 'gatsby'

import Gallery from './Gallery'

class Portfolio extends React.Component {
  render() {
    return (
      <Gallery
        images={this.props.posts.map((node) => {
          const path =
            this.props.basepath +
            String(node.frontmatter.path).replace(/^\s*\/?(.*?)\/?\s*$/, '/$1')
          const onClick = (e) => {
            navigate(path)
            e.preventDefault()
          }
          return {
            source: node.frontmatter.image.childImageSharp.full.src,
            srcset: node.frontmatter.image.childImageSharp.full.srcSet,
            thumbnail: node.frontmatter.image.childImageSharp.thumbnail.src,
            thumbnailFluid: node.frontmatter.image.childImageSharp.thumbnail,
            thumbnailCaption: (
              <a onClick={onClick} href={path}>
                {node.frontmatter.title}
              </a>
            ),
            path,
            onClick,
            title: node.frontmatter.title,
            caption: (
              <a onClick={onClick} href={path}>
                {node.frontmatter.title}
              </a>
            ),
            html: node.excerpt,
          }
        })}
      />
    )
  }
}

Portfolio.propTypes = {
  posts: PropTypes.array,
}

export default Portfolio

export const portfolioPost = graphql`
  fragment PortfolioPost on MarkdownRemark {
    frontmatter {
      path
      date
      title
      image {
        childImageSharp {
          full: fluid(maxWidth: 740, traceSVG: { blackOnWhite: true }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          thumbnail: fluid(
            maxWidth: 464
            maxHeight: 272
            cropFocus: NORTH
            traceSVG: { blackOnWhite: true }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    excerpt
  }
`
