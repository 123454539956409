import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Img from 'gatsby-image'
import _ from 'lodash'
import LazyLoad from 'react-lazyload'

function FooterCaption({ currentView }) {
  return (
    <span>
      {currentView.title}
      <a
        style={{ marginLeft: '1em' }}
        onClick={currentView.onClick}
        href={currentView.path}
      >
        ... detail
      </a>
    </span>
  )
}

function FooterCount({ currentIndex, views }) {
  return (
    <span>
      {currentIndex + 1} / {views.length}
    </span>
  )
}

class Gallery extends Component {
  constructor() {
    super()

    this.state = {
      lightboxIsOpen: false,
      selectedIndex: 0,
    }

    this.toggleLightbox = this.toggleLightbox.bind(this)
  }
  toggleLightbox(selectedIndex) {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }))
  }
  renderGallery(images) {
    if (!images) return

    const gallery = _.chunk(images, 2).map((pair, i) => {
      return (
        <div className="row" key={i}>
          {pair.map((obj, j) => {
            return (
              <article className="6u 12u$(xsmall) work-item" key={i + '-' + j}>
                <a
                  className="image fit thumb"
                  href={obj.source}
                  onClick={(e) => {
                    e.preventDefault()
                    this.toggleLightbox(i * 2 + j)
                  }}
                  aria-label={`Zvětšit ${obj.title}`}
                >
                  <LazyLoad
                    once
                    debounce
                    placeholder={<div className="image-placeholder"></div>}
                  >
                    <Img fluid={obj.thumbnailFluid} alt={obj.title} />
                  </LazyLoad>
                </a>

                <h3>{obj.caption}</h3>
                <p>{obj.description}</p>
              </article>
            )
          })}
        </div>
      )
    })

    return <div>{gallery}</div>
  }

  render() {
    const { images } = this.props
    const { selectedIndex, lightboxIsOpen } = this.state

    return (
      <div>
        {this.renderGallery(images)}
        <ModalGateway>
          {lightboxIsOpen && (
            <Modal onClose={this.toggleLightbox}>
              <Carousel
                currentIndex={selectedIndex}
                views={images}
                components={{ FooterCaption, FooterCount }}
              />
            </Modal>
          )}
        </ModalGateway>
      </div>
    )
  }
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  images: PropTypes.array,
}

export default Gallery
